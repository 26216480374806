import {MerchantActions, MerchantActionTypes, MerchantState} from "../../types/Merchant";

export const initialState: MerchantState = {
    merchantData: {
        merchant: "",
        clientId: "test_ID",
        transactionId: "testID",
        desc: "product",
        amount: 0,
        redirectUrl: "",
        callbackUrl: ""
    },
    merchantResponse: {
        result: 10,
        desc: "",
        id: "",
        payUrl: ""
    },
    loading: false,
    error: null,
}

export const merchantReducer = (state = initialState, action: MerchantActions): MerchantState => {
    switch (action.type) {
        case MerchantActionTypes.SET_DATA_MERCHANT:
            return {...state, merchantData: action.payload}
        case MerchantActionTypes.FETCH_MERCHANT_INVOICE:
            return {...state, loading: true, error: null}
        case MerchantActionTypes.FETCH_MERCHANT_INVOICE_SUCCESS:
            return {...state, loading: false, merchantResponse: action.payload}
        case MerchantActionTypes.FETCH_MERCHANT_INVOICE_ERROR:
            return {...state, loading: false, error: action.payload}
        default:
            return state
    }
}