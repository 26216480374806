import {IAuthResponse} from "../model/IAuth";

export interface AuthState {
    auth: boolean,
    phone: string,
    responseAuth: IAuthResponse,
    password: string,
    sentOTP: boolean,
    error: null | string,
    loading?: boolean,
}

export enum LoginActionTypes {
    SET_PHONE = "SET_PHONE",
    SET_PASSWORD = "SET_PASSWORD",
    SET_LOADING = "SET_LOADING",
    SET_IS_AUTH = "SET_IS_AUTH",
    FETCH_OTP = "FETCH_OTP",
    FETCH_OTP_SUCCESS = "FETCH_OTP_SUCCESS",
    FETCH_OTP_ERROR = "FETCH_OTP_ERROR",
    FETCH_AUTH = "FETCH_AUTH",
    FETCH_AUTH_SUCCESS = "FETCH_AUTH_SUCCESS",
    FETCH_AUTH_ERROR = "FETCH_AUTH_ERROR",
}

interface SetPhoneAction {
    type: LoginActionTypes.SET_PHONE,
    payload: string,
}
interface SetLoadingAction {
    type: LoginActionTypes.SET_LOADING,
    payload: boolean,
}

interface SetPasswordAction {
    type: LoginActionTypes.SET_PASSWORD,
    payload: string,
}
interface SetIsAuthAction {
    type: LoginActionTypes.SET_IS_AUTH,
    payload: boolean,
}

interface FetchOTPAction {
    type: LoginActionTypes.FETCH_OTP,
}

interface FetchOTPSuccessAction {
    type: LoginActionTypes.FETCH_OTP_SUCCESS,
}

interface FetchOTPErrorAction {
    type: LoginActionTypes.FETCH_OTP_ERROR,
    payload: string,
}

interface FetchAuthAction {
    type: LoginActionTypes.FETCH_AUTH,
}

interface FetchAuthSuccessAction {
    type: LoginActionTypes.FETCH_AUTH_SUCCESS,
    payload: IAuthResponse,
}

interface FetchAuthErrorAction {
    type: LoginActionTypes.FETCH_AUTH_ERROR,
    payload: string,
}

export type LoginAction =
    SetPhoneAction
    | SetLoadingAction
    | SetIsAuthAction
    | SetPasswordAction
    | FetchOTPAction
    | FetchOTPSuccessAction
    | FetchOTPErrorAction
    | FetchAuthAction
    | FetchAuthSuccessAction
    | FetchAuthErrorAction

