/* eslint-disable react-hooks/exhaustive-deps */

import React, {FC, useEffect} from 'react';
import {Redirect, Route, Switch} from "react-router-dom";
import {publicRoutesEvercash, RouteNamesEvercash} from "../../routes";
import {createTheme, ThemeProvider} from "@mui/material";
import {Colors} from "../../styles/styles";
import {useTypeSelector} from "../../hooks/useTypeSelector";
import {useActions} from "../../hooks/useActions";

const EvercashRoute: FC = () => {
    const {initResponse} =useTypeSelector(state => state.init);
    const {fetchCryptoCurrencyList} =useActions();
    const themeFortune = createTheme({
        palette: {
            primary: Colors.fortune.primary,
            secondary: Colors.fortune.secondary
        },
    });

    useEffect(()=>{
        (function(w,d,u){
            const s=d.createElement('script');
            s.async=true;
            s.src=u+'?'+(Date.now()/60000|0);
            const h=d.getElementsByTagName('script')[0];
            h.parentNode && h.parentNode.insertBefore(s,h);
        })(window,document,'https://bitrix.esterna-holding.com/upload/crm/site_button/loader_7_v4vuax.js');
    },[])

    useEffect(()=>{
        if (initResponse.result === 0) {
            fetchCryptoCurrencyList(initResponse.amount, 1)
        }
    },[initResponse.result]);

    return (
        <ThemeProvider theme={themeFortune}>
                    <Switch>
                        {publicRoutesEvercash.map(route =>
                            <Route
                                key={route.path}
                                path={route.path}
                                component={route.component}
                                exact={route.exact}
                            />
                        )}
                        <Redirect to={RouteNamesEvercash.ERROR}/>
                    </Switch>
        </ThemeProvider>
    );
};

export default EvercashRoute;