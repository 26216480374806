import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../utils/config";
import {IMerchantData} from "../../model/IMerchant";
import {MerchantActions, MerchantActionTypes} from "../../types/Merchant";


export const merchantActionsCreator = {
    setMerchantData: (data: IMerchantData) => (dispatch: Dispatch<MerchantActions>) => {
        dispatch({type: MerchantActionTypes.SET_DATA_MERCHANT, payload: data})
    },

    fetchInvoice: (data: IMerchantData, token: string) => async (dispatch: Dispatch<MerchantActions>) => {
        try {
            dispatch({type: MerchantActionTypes.FETCH_MERCHANT_INVOICE})
            const response = await axios.post('merchant/invoice/', data, {
                baseURL: config.url.API_URL,
                headers: {
                    // FRTN
                    // "Authorization": `Bearer 9986d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a64`
                    // JP
                    // "Authorization": `Bearer 9f86d081884c7d659a2feaa0c55ad015a3bf4f1b2b0b822cd15d6c15b0f00a08`
                    "Authorization": `Bearer ${token}`
                }
            })
            if (response.data.result === 0) {
                dispatch({type: MerchantActionTypes.FETCH_MERCHANT_INVOICE_SUCCESS, payload: response.data})
            } else {
                dispatch({
                    type: MerchantActionTypes.FETCH_MERCHANT_INVOICE_ERROR,
                    payload: `ERROR Invoice ${response.data}`
                })
            }
        } catch (e: any) {
            dispatch({type: MerchantActionTypes.FETCH_MERCHANT_INVOICE_ERROR, payload: `ERROR Invoice ${e.response}`})
        }
    },
}