/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from 'react';
import {
    Box,
    Button,
    ButtonProps,
    styled,
    TextField,
    Typography
} from "@mui/material";
import {Colors} from "../styles/styles";
import {useTypeSelector} from "../hooks/useTypeSelector";
import {useActions} from "../hooks/useActions";

const ColorButton = styled(Button)<ButtonProps>(() => ({
    minWidth: 150,
    color: "#FFFFFF",
    backgroundColor: "#50AF9A",
    '&:hover': {
        backgroundColor: "#51A693",
    },
    '&:disabled': {
        backgroundColor: Colors.secondary,
        color: Colors.textPrimary
    },
}));

const Merchant = () => {
    const {merchantData, merchantResponse,} = useTypeSelector(state => state.merchant);
    const {setMerchantData, fetchInvoice} = useActions();
    const [errorAmount, setErrorAmount] = useState("");
    const [token, setToken] = React.useState('');

    const handleChangeDataMerchant = (event: React.ChangeEvent<HTMLInputElement>) => {
        setErrorAmount("");
        setMerchantData({...merchantData, [event.target.name]: event.target.value});
    };

    const handleChangeToken = (event: React.ChangeEvent<HTMLInputElement>) => {
       setToken(event.target.value)
    };

    const submit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        if (merchantData.amount > 0) {
            fetchInvoice(merchantData, token);
        } else {
            setErrorAmount("The amount must be greater than 0");
        }
    };

    useEffect(() => {
        if (merchantResponse.result === 0) {
            window.location.replace(merchantResponse.payUrl)
        }
    }, [merchantResponse.result])

    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            paddingY={5}
        >
            <Typography
                variant={"h4"}
                component={"h1"}
                textAlign={"center"}
                color={Colors.textPrimary}
                marginBottom={3}
            >
                Welcome to merchant page!
            </Typography>
            <Box
                component={"form"}
                noValidate
                autoComplete={"off"}
                onSubmit={submit}
            >
                <TextField
                    margin={"normal"}
                    fullWidth
                    id="token"
                    name="token"
                    label="Token"
                    type="text"
                    value={token}
                    onChange={handleChangeToken}
                />
                <TextField
                    margin={"normal"}
                    fullWidth
                    id="merchant"
                    name="merchant"
                    label="Merchant ID"
                    type="text"
                    value={merchantData.merchant}
                    onChange={handleChangeDataMerchant}
                />
                <TextField
                    margin={"normal"}
                    fullWidth
                    id="clientId"
                    name="clientId"
                    label="Client ID"
                    type="text"
                    value={merchantData.clientId}
                    onChange={handleChangeDataMerchant}
                />
                <TextField
                    margin={"normal"}
                    fullWidth
                    id="transactionId"
                    name="transactionId"
                    label="Transaction ID"
                    type="text"
                    value={merchantData.transactionId}
                    onChange={handleChangeDataMerchant}
                />
                <TextField
                    margin={"normal"}
                    fullWidth
                    id="desc"
                    name="desc"
                    label="Description"
                    type="text"
                    value={merchantData.desc}
                    onChange={handleChangeDataMerchant}
                />
                <TextField
                    margin={"normal"}
                    fullWidth
                    error={!!errorAmount}
                    helperText={errorAmount}
                    id="amount"
                    name="amount"
                    label="Amount"
                    type="number"
                    InputLabelProps={{
                        shrink: true,
                    }}
                    value={merchantData.amount}
                    onChange={handleChangeDataMerchant}
                />
                <TextField
                    margin={"normal"}
                    fullWidth
                    id="redirectUrl"
                    name="redirectUrl"
                    label="Redirect URL"
                    type="text"
                    value={merchantData.redirectUrl}
                    onChange={handleChangeDataMerchant}
                />
                <TextField
                    margin={"normal"}
                    fullWidth
                    id="callbackUrl"
                    name="callbackUrl"
                    label="Callback URL"
                    type="text"
                    value={merchantData.callbackUrl}
                    onChange={handleChangeDataMerchant}
                    sx={{
                        marginBottom: 3
                    }}
                />
                <ColorButton
                    type={"submit"}
                    fullWidth
                >
                    Buy
                </ColorButton>
            </Box>
        </Box>
    );
};

export default Merchant;