/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState} from 'react';

import {Box, Typography} from "@mui/material";

const MokMerchant:FC = () => {
    const search = window.location.search.substring(1);
    const [loading, setLoading] = useState(true);
    const [params, setParams] = useState({
        amount: "",
        product: ""
    });
    useEffect(() => {
        if (search.includes("amount") && search.includes("product")) {
            let obj = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
                return key === "" ? value : decodeURIComponent(value)
            })
          setParams({...params, amount: obj.amount, product: obj.product})
        }
        setLoading(false)
    }, []);

    if(loading) {
        return <Typography>...Loading</Typography>
    }

    return (
        <Box
            component="div"
            minWidth={340}
            maxWidth={400}
        >
            <Box
                component="div"
                marginBottom={2}
            >
                <Typography
                    variant="h5"
                    align="left"
                    marginBottom={2}
                    color="#50AF9A"
                >
                    Thank you for your purchase.
                </Typography>
                <Typography
                    paragraph
                    align="left"
                    marginBottom={0}
                    color="#A1A1A1"
                >
                    Your product:
                </Typography>
                <Typography
                    paragraph
                    align="left"
                >
                    {params.product}
                </Typography>
                <Box>
                    <Typography
                        paragraph
                        align="left"
                        marginBottom={0}
                        color="#A1A1A1"
                    >
                        Total price
                    </Typography>
                    <Typography
                        variant="h4"
                        align="left"
                        marginBottom={2}
                    >
                        € {params.amount}
                    </Typography>
                    <Typography
                        variant="h4"
                        align="center"
                        color="#50AF9A"
                    >
                        Paid successfully!
                    </Typography>
                </Box>
            </Box>
        </Box>
    );
};

export default MokMerchant;