/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import {useTypeSelector} from "../../hooks/useTypeSelector";
import {useActions} from "../../hooks/useActions";
import {Box, Card, CardActions, CardContent, CardMedia, Typography} from "@mui/material";
import image from "../../assets/cross.png";
import LoadingButton from "@mui/lab/LoadingButton";

const FortuneMerchantTest = () => {
    const {merchantData, merchantResponse, loading} = useTypeSelector(state => state.merchant);
    const {fetchInvoice} = useActions();

    const submit = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        fetchInvoice({
            ...merchantData,
            callbackUrl: "https://private-22c315-max81.apiary-mock.com/questions",
            redirectUrl: "https://pay-dev.hybrid-acquiring.eu/merchant-completed",
            amount: 2.35,
            // FRTN
            merchant: "de6ab136-9b70-4cfe-975f-84775a1cf38c",
            // EVER
            // merchant: "beb615a8-6b4b-4196-99b8-4c1072faed35",
            clientId: "test_ID",
            transactionId: "testID",
            desc: "sneakers",
            // FRTN
        }, "b79c46fc0dc29bdc5e7f5cfff906ba422687531c1767339d7dc035e04f3d051b");
        //     EVER
        // }, "c426c728ca76a4cd735f48ea23441458e5886a178a66abd5eaf30abd496314db");
    };

    useEffect(() => {
        if (merchantResponse.result === 0) {
            window.location.replace(merchantResponse.payUrl)
        }
    }, [merchantResponse.result])
    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            paddingY={5}
        >
            <Card sx={{maxWidth: 345}}>

                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Sneakers
                    </Typography>

                    <CardMedia
                        component="img"
                        height="340"
                        image={image}
                        alt="sneakers"
                    />
                    <Box
                        display={"flex"}
                        marginBottom={3}
                    >
                        <Typography marginRight={3} variant="body1">
                            size: 46
                        </Typography>
                        <Typography marginRight={3} variant="body1">
                            2.35 Euro
                        </Typography>
                        <Typography variant="body1">
                            Qty: 1
                        </Typography>
                    </Box>

                    <Typography variant="body2" color="text.secondary">
                        Whether you want to&nbsp;run long distances or&nbsp;do&nbsp;some quick runs in&nbsp;the
                        park, the Asics Jolt 2&nbsp;running shoes are a&nbsp;great choice. Reliable cushioning
                        technology provides good all-round protection and ride comfort.
                    </Typography>
                    <Box
                        marginTop={3}
                    >
                        <Typography marginBottom={1} variant="body1">
                            Order Total: 2.35 Euro
                        </Typography>
                        <Typography variant="body1">
                            Order Totals include VAT
                        </Typography>
                    </Box>
                </CardContent>
                <CardActions>
                    <LoadingButton
                        loading={loading}
                        onClick={submit}
                        fullWidth
                    >
                        PAY
                    </LoadingButton>
                </CardActions>
            </Card>
        </Box>
    );
};

export default FortuneMerchantTest;