/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect} from 'react';
import {useTypeSelector} from "../../hooks/useTypeSelector";
import {createTheme, ThemeProvider} from "@mui/material";
import {Colors} from "../../styles/styles";
import {Redirect, Route, Switch} from "react-router-dom";
import {
    privateRoutesJackPayments,
    publicRoutesJackPayments,
    RouteNamesJackPayments
} from "../../routes";
import {useActions} from "../../hooks/useActions";

const JackPaymentsRoute: FC = () => {
    const {auth} = useTypeSelector(state => state.auth);
    const {invoiceID} = useTypeSelector(state => state.init);
    const {fetchCheckStatus} = useActions();
    useEffect(()=>{
        fetchCheckStatus(invoiceID)
    },[]);
    const themeJackPayments = createTheme({
        palette: {
            primary: Colors.fortune.primary,
            secondary: Colors.fortune.secondary
        },
    });
    return (
        <ThemeProvider theme={themeJackPayments}>
            {
                auth
                    ?
                    <Switch>
                        {privateRoutesJackPayments.map(route =>
                            <Route
                                key={route.path}
                                path={route.path}
                                component={route.component}
                                exact={route.exact}
                            />
                        )}
                        <Redirect to={RouteNamesJackPayments.ORDER_EUPI}/>
                    </Switch>
                    :
                    <Switch>
                        {publicRoutesJackPayments.map(route =>
                            <Route
                                key={route.path}
                                path={route.path}
                                component={route.component}
                                exact={route.exact}
                            />
                        )}
                        <Redirect to={RouteNamesJackPayments.PAYMENT}/>
                    </Switch>
            }
        </ThemeProvider>
    );
};

export default JackPaymentsRoute;