import {IChoiceData, IChoiceResponse} from "../model/IChoice";

export interface ChoiceState {
    choiceData: IChoiceData,
    choiceResponse: IChoiceResponse
    error: null | string;
    loading?: boolean;
}

export enum ChoiceActionTypes {
    SET_CURRENCY = "SET_CURRENCY",
    FETCH_CHOICE = "FETCH_CHOICE",
    FETCH_CHOICE_SUCCESS = "FETCH_CHOICE_SUCCESS",
    FETCH_CHOICE_ERROR = "FETCH_CHOICE_ERROR",
}

interface InvoiceActionTypes {
    type: ChoiceActionTypes.SET_CURRENCY;
    payload: IChoiceData;
}

interface FetchChoiceAction {
    type: ChoiceActionTypes.FETCH_CHOICE;
}

interface FetchChoiceSuccessAction {
    type: ChoiceActionTypes.FETCH_CHOICE_SUCCESS;
    payload: IChoiceResponse;
}

interface FetchChoiceErrorAction {
    type: ChoiceActionTypes.FETCH_CHOICE_ERROR;
    payload: string;
}

export type ChoiceActions =
    InvoiceActionTypes
    | FetchChoiceAction
    | FetchChoiceSuccessAction
    | FetchChoiceErrorAction
