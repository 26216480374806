import {dictActionsCreator} from "./dict";
import {initActionsCreator} from "./init";
import {choiceActionsCreator} from "./choice";
import {checkStatusActionsCreator} from "./checkStatus";
import {merchantActionsCreator} from "./merchant";
import {authActionsCreator} from "./auth";



const exportedObject = {
    ...dictActionsCreator,
    ...initActionsCreator,
    ...choiceActionsCreator,
    ...checkStatusActionsCreator,
    ...merchantActionsCreator,
    ...authActionsCreator,
}

export default exportedObject