/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';

import {useTypeSelector} from "../hooks/useTypeSelector";
import {useActions} from "../hooks/useActions";

import {
    Alert,
    Box,
    Button,
    MenuItem,
    Snackbar,
    TextField,
    Typography
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";

import Error from "./Error";
import {useHistory} from "react-router-dom";
import {RouteNamesFortune} from "../routes";
import {getAmountDue} from "../utils/getAmountDue";
import moment from "moment";

const PayByCrypto = () => {
    const history = useHistory();
    const {cryptoCurrencyList} = useTypeSelector(state => state.dict);
    const {invoiceID, initResponse} = useTypeSelector(state => state.init);
    const {choiceResponse, error} = useTypeSelector(state => state.choice);
    const {checkStatusResponse} = useTypeSelector(state => state.checkStatus);
    const {fetchChoice, setIsAuth, fetchCheckStatus} = useActions();
    const [open, setOpen] = React.useState(false);
    const [openAmountDue, setOpenAmountDue] = React.useState(false);
    const [seconds, setSeconds] = React.useState(5);
    const [secondsRedirect, setSecondsRedirect] = React.useState(15);

    const handleChangeCurrency = (event: React.ChangeEvent<HTMLInputElement>) => {
        fetchChoice({crypto: true, currencyCode: event.target.value}, invoiceID);

    };
    const copyAddress = () => {
        let address = "";
        if (choiceResponse.address) {
            address = choiceResponse.address
        }
        if (checkStatusResponse.crypto?.address) {
            address = checkStatusResponse.crypto.address
        }
        navigator.clipboard.writeText(address);
        setOpen(true);
    };
    const copyAmountDue = (amount: any) => {
        navigator.clipboard.writeText(amount);
        setOpenAmountDue(true);
    };

    const handleCloseAmountDue = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpenAmountDue(false);
    };
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const BackToTheStore = () => {
        setIsAuth(false)
        if (initResponse.redirectUrl) {
            document.location.href = initResponse.redirectUrl;
        } else {
            history.push(RouteNamesFortune.ORDER_MERCH + `?amount=${initResponse.amount}&product=${initResponse.transactionDesc}`)
        }
    }

    useEffect(() => {
        if (checkStatusResponse.payStatus.name === "awaiting crypto") {
            if (seconds > 0) {
                setTimeout(() => {
                    setSeconds((seconds) => seconds - 1)
                }, 1000);
            }
            if (seconds === 0) {
                fetchCheckStatus(invoiceID);
                setSeconds(5)
            }
        }
    }, [choiceResponse, seconds, checkStatusResponse.payStatus.name])

    useEffect(() => {
        if (checkStatusResponse.payStatus.final) {
            if (secondsRedirect > 0) {
                setTimeout(() => {
                    setSecondsRedirect((secondsRedirect) => secondsRedirect - 1)
                }, 1000);
            }
            if (secondsRedirect === 0) {
                document.location.href = initResponse.redirectUrl;
                setSecondsRedirect(15);
            }
        }
    }, [checkStatusResponse.payStatus, secondsRedirect])

    if (error) {
        return <Error>{error}</Error>
    }

    return (
        <Box>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{width: '100%'}}
                >
                    Payment address copy
                </Alert>
            </Snackbar>

            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                open={openAmountDue}
                autoHideDuration={6000}
                onClose={handleCloseAmountDue}
            >
                <Alert
                    onClose={handleCloseAmountDue}
                    severity="success"
                    sx={{width: '100%'}}
                >
                    Amount due copy
                </Alert>
            </Snackbar>
            {
                checkStatusResponse.payStatus.final
                    ?
                    <Box>
                        <Typography
                            paragraph
                            align="left"
                            marginBottom={2}
                            color="#252525"
                        >
                            {`Thank you for paying through the Crypto service. After ${secondsRedirect} seconds, you will
                                be redirected back to the shop.`}
                        </Typography>
                        <Button onClick={BackToTheStore}>Back to the store</Button>
                    </Box>
                    :
                    <Box>
                        <TextField
                            id="currency"
                            select
                            disabled={checkStatusResponse.payStatus.name === "awaiting crypto" || !!checkStatusResponse.crypto?.address || !!choiceResponse.currency}
                            fullWidth
                            label="Select cryptocurrency"
                            value={choiceResponse.currency || checkStatusResponse.crypto?.currency}
                            onChange={handleChangeCurrency}
                        >
                            {cryptoCurrencyList.cryptoRate.map(currency =>
                                <MenuItem disabled={!currency.available} key={currency.name} value={currency.code}><img
                                    style={{width: "16px", marginRight: 10}}
                                    src={currency.logo}
                                    alt={currency.name}/> {currency.available ? `${currency.name}` : `${currency.name} minimum ${currency.minDeposit} ${currency.code}`}
                                </MenuItem>
                            )}
                        </TextField>
                        {
                            (checkStatusResponse.crypto?.currency || choiceResponse.crypto) &&
                            <Box>
                                <Box
                                    component="div"
                                    justifyContent="center"
                                    display="flex"
                                    marginBottom={2}
                                >
                                    <img src={choiceResponse.qr || checkStatusResponse.crypto?.qr}
                                         alt={choiceResponse.currency || checkStatusResponse.crypto?.currency}/>
                                </Box>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    fontWeight={600}
                                    fontSize={13}
                                    gutterBottom
                                >
                                    {`The payment address is valid till ${moment.unix(Number(checkStatusResponse.crypto?.dueDate)).format("DD/MM/YYYY HH:hh")} UTC:`}
                                </Typography>
                                <Typography
                                    paragraph
                                    onClick={copyAddress}
                                    sx={{
                                        wordWrap: "break-word",
                                        cursor: "pointer",
                                    }}
                                    marginBottom={2}
                                >
                                    {choiceResponse.address || checkStatusResponse.crypto?.address} <ContentCopyIcon/>
                                </Typography>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    color="#BC3C3C"
                                >
                                    {`We are awaiting your full payment of ${checkStatusResponse.crypto?.expected.toFixed(9)} ${checkStatusResponse.crypto?.currency}`}
                                </Typography>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    color="#BC3C3C"
                                >
                                    {`Amount received: ${checkStatusResponse.crypto?.totalAmount.toFixed(9)} ${checkStatusResponse.crypto?.currency}`}
                                </Typography>
                                <Typography
                                    onClick={copyAmountDue.bind(null, Number(getAmountDue(checkStatusResponse.crypto?.expected, checkStatusResponse.crypto?.totalAmount).getValue()).toFixed(9))}
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    sx={{
                                        cursor: "pointer",
                                    }}
                                    color="#BC3C3C"
                                >
                                    {`Amount due: ${Number(getAmountDue(checkStatusResponse.crypto?.expected, checkStatusResponse.crypto?.totalAmount).getValue()).toFixed(9)} ${checkStatusResponse.crypto?.currency} \xa0`}
                                    <sup
                                        style={{
                                            lineHeight: 0,
                                            fontSize: 10
                                        }}
                                    >copy</sup><ContentCopyIcon sx={{ fontSize: 14}}/>
                                </Typography>
                                <Typography
                                    variant="caption"
                                    display="block"
                                    gutterBottom
                                    color="#BC3C3C"
                                >
                                    If full payment is not received by the due time this offer will be cancelled and you
                                    will need to contact our support to complete the payment with a new rate.
                                </Typography>
                            </Box>
                        }
                    </Box>
            }
        </Box>
    );
};

export default PayByCrypto;