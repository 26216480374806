import {ChoiceActions, ChoiceActionTypes, ChoiceState} from "../../types/choice";


export const initialState: ChoiceState = {
    choiceData: {
        crypto: false,
        currencyCode: ""
    },
    choiceResponse: {
        result: 10,
        desc: "",
        crypto: false,
        streamPayments: {
            id: ""
        },
        realProcessing: {
            url: ""
        },
        qr: "",
        address: "",
        total: 0,
        commission: 0,
        currency: ""
    },
    error: null,
    loading: false
}

export const choiceReducer = (state = initialState, action: ChoiceActions): ChoiceState => {
    switch (action.type) {
        case ChoiceActionTypes.SET_CURRENCY:
            return {...state, choiceData: action.payload}
        case ChoiceActionTypes.FETCH_CHOICE:
            return {...state, loading: true, error: null}
        case ChoiceActionTypes.FETCH_CHOICE_SUCCESS:
            return {...state, loading: false, choiceResponse: action.payload}
        case ChoiceActionTypes.FETCH_CHOICE_ERROR:
            return {...state, loading: false, error: action.payload}
        default:
            return state
    }
}