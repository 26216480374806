import {IMerchantData, IMerchantResponse} from "../model/IMerchant";


export interface MerchantState {
    merchantData: IMerchantData,
    merchantResponse: IMerchantResponse,
    error: null | string,
    loading: boolean,
}

export enum MerchantActionTypes {
    SET_DATA_MERCHANT = "SET_DATA_MERCHANT",
    FETCH_MERCHANT_INVOICE = "FETCH_MERCHANT_INVOICE",
    FETCH_MERCHANT_INVOICE_SUCCESS = "FETCH_MERCHANT_INVOICE_SUCCESS",
    FETCH_MERCHANT_INVOICE_ERROR = "FETCH_MERCHANT_INVOICE_ERROR",
}

interface SetDataMerchant {
    type: MerchantActionTypes.SET_DATA_MERCHANT,
    payload: IMerchantData
}

interface FetchMerchantInvoice {
    type: MerchantActionTypes.FETCH_MERCHANT_INVOICE,
}

interface FetchMerchantInvoiceSuccess {
    type: MerchantActionTypes.FETCH_MERCHANT_INVOICE_SUCCESS,
    payload: IMerchantResponse
}

interface FetchMerchantInvoiceError {
    type: MerchantActionTypes.FETCH_MERCHANT_INVOICE_ERROR,
    payload: string
}

export type MerchantActions =
    SetDataMerchant
    | FetchMerchantInvoice
    | FetchMerchantInvoiceSuccess
    | FetchMerchantInvoiceError