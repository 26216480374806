/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from 'react';
import {Box, Button, Typography} from "@mui/material";
import {useTypeSelector} from "../hooks/useTypeSelector";
import {RouteNamesFortune} from "../routes";
import {useHistory} from "react-router-dom";
import {useActions} from "../hooks/useActions";

const OrderEupi = () => {
    const history = useHistory();
    const {checkStatusResponse} = useTypeSelector(state => state.checkStatus);
    const {initResponse} = useTypeSelector(state => state.init);
    const {setIsAuth} = useActions();
    const [seconds, setSeconds] = React.useState(5);
    const BackToTheStore = () => {
        setIsAuth(false);
        if(initResponse.redirectUrl) {
            document.location.href = initResponse.redirectUrl;
        } else {
            history.push(RouteNamesFortune.ORDER_MERCH + `?amount=${initResponse.amount}&product=${initResponse.transactionDesc}`)
        }
    }

    useEffect(() => {
            if (seconds > 0) {
                setTimeout(() => {
                    setSeconds((seconds) => seconds - 1)
                }, 1000);
            }
            if (seconds === 0) {

            }
    }, [seconds])

    return (
        <Box
            component="div"
            minWidth={340}
            maxWidth={400}
        >
            <Box
                component="div"
                marginBottom={2}
            >
                <Typography
                    variant="h5"
                    align="left"
                    marginBottom={2}
                    color="#50AF9A"
                >
                    {initResponse.merchantName}
                </Typography>
                <Typography
                    paragraph
                    align="left"
                    marginBottom={0}
                    color="#A1A1A1"
                >
                    Products
                </Typography>
                <Typography
                    paragraph
                    align="left"
                >
                    {initResponse.transactionDesc}
                </Typography>
                <Box>
                    <Typography
                        paragraph
                        align="left"
                        marginBottom={0}
                        color="#A1A1A1"
                    >
                        Total price
                    </Typography>
                    <Typography
                        variant="h4"
                        align="left"
                        marginBottom={2}
                    >
                        € {initResponse.amount}
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        paragraph
                        align="left"
                        marginBottom={0}
                        color="#A1A1A1"
                    >
                        Total price in crypto currency
                    </Typography>
                    <Typography
                        variant="h4"
                        align="left"
                    >
                        {`${checkStatusResponse.fiat?.total}  ${checkStatusResponse.fiat?.currency}`}
                    </Typography>
                </Box>
                <Box>
                    <Typography
                        paragraph
                        align="left"
                        marginBottom={2}
                        color="#252525"
                    >
                        {`Thank you for paying through the Crypto service. After ${seconds} seconds, you will
                                be redirected back to the shop.`}
                    </Typography>
                    <Button onClick={BackToTheStore}>Back to the store</Button>
                </Box>
            </Box>
        </Box>
    );
};

export default OrderEupi;