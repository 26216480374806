export const Colors = {
    fortune: {
        primary: {
            // light:Colors.fortune.primary,
            main: "#51A693",
            // dark: Colors.fortune.primary,
            contrastText: "#FFF",
        },
        secondary: {
            // light:Colors.fortune.primary,
            main: "#B4B4B4",
            // dark: Colors.fortune.primary,
            contrastText: "#FFF",
        },
        // textPrimary: "#333",
    },
    modal: {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    },
    primary: "#51A693",
    secondary: "#B4B4B4",
    textPrimary: "#333",
}