/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useRef, useState} from 'react';

import {useTypeSelector} from "../hooks/useTypeSelector";
import {useActions} from "../hooks/useActions";

import {config} from '../utils/config';

import LoadingButton from '@mui/lab/LoadingButton';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import {Alert, Backdrop, Box, Button, CircularProgress, Collapse, Snackbar, Typography} from "@mui/material";
import PayByCrypto from "./PayByCrypto";
import Error from "./Error";
import {Colors} from "../styles/styles";
import CopyAmount from "./CopyAmount";

const scrollToRef = (ref: any) => window.scrollTo(0, ref.current.offsetTop)
const Payment = () => {
    const {initResponse, invoiceID} = useTypeSelector(state => state.init);
    const {checkStatusResponse, error} = useTypeSelector(state => state.checkStatus);
    const loadingChoice = useTypeSelector(state => state.choice.loading);
    const {choiceResponse, loading} = useTypeSelector(state => state.choice);
    const [showSelectBlock, setShowSelectBlock] = useState(false);
    const {fetchChoice, fetchCheckStatus} = useActions();
    const [open, setOpen] = React.useState(false);

    const myRef = useRef(null)
    const executeScroll = () => scrollToRef(myRef)

    const handleClickBuyCrypto = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setShowSelectBlock(false)
        fetchChoice({crypto: false}, invoiceID)
    }

    const handleClickPayFromOtherWallet = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        setShowSelectBlock(!showSelectBlock)
    }

    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };


    useEffect(() => {
        if (choiceResponse.result === 0) {
            fetchCheckStatus(invoiceID)
            executeScroll();
        }
    }, [choiceResponse.result])

    useEffect(() => {
        if (checkStatusResponse.result === 0 && checkStatusResponse.crypto) {
            setShowSelectBlock(true)
        }
    }, [checkStatusResponse.crypto])

    useEffect(() => {
        if (checkStatusResponse.payStatus.name === "awaiting fiat" && initResponse.partnerCode === "frtn") {
            window.location.href = config.url.URL_FORTUNE_LOGIN + `?invoiceId=${invoiceID}`;
        }
    }, [checkStatusResponse.payStatus.name])
    console.log("paymentPage: ", checkStatusResponse)
    if (error === "ERROR!!! invoice id - d4c6766a-4292-4b28-b30a-0dfc9b433c13 not found") {
        return (
            <Error>{error}</Error>
        )
    }
    return (
        <Box>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{width: '100%'}}
                >
                    Amount copy
                </Alert>
            </Snackbar>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
                open={!!(loading || loadingChoice)}
            >
                <CircularProgress color="inherit"/>
            </Backdrop>
            <Box
                component="div"
                marginBottom={3}
            >
                <Typography
                    variant="h5"
                    align="left"
                    marginBottom={2}
                    color="#50AF9A"
                >
                    {initResponse.merchantName}
                </Typography>
                <Typography
                    paragraph
                    align="left"
                    marginBottom={0}
                    color="#A1A1A1"
                >
                    Products
                </Typography>
                <Typography
                    paragraph
                    align="left"
                >
                    {initResponse.transactionDesc}
                </Typography>
                {
                    (checkStatusResponse.crypto?.currency || choiceResponse.crypto) ?
                    <CopyAmount/>
                        :
                        <Box>
                            <Typography
                                paragraph
                                align="left"
                                marginBottom={0}
                                color="#A1A1A1"
                            >
                                Total price
                            </Typography>
                            <Typography
                                variant="h4"
                                align="left"
                                marginBottom={2}
                            >
                                € {initResponse.amount}
                            </Typography>
                        </Box>
                }
            </Box>

            <Box
                marginBottom={2}
                display={"flex"}
                justifyContent={"space-between"}
            >
                <LoadingButton
                    disabled={(initResponse.crypto || choiceResponse.crypto)}
                    sx={{
                        width: "45%",
                        padding: 2
                    }}
                    variant={(initResponse.crypto || choiceResponse.crypto) ? "outlined" : "contained"}
                    onClick={handleClickBuyCrypto}
                    loading={loading}
                    loadingIndicator="Loading..."
                >
                    Buy crypto and pay on&nbsp;the fortune site
                </LoadingButton>
                <Button
                    disabled={initResponse.crypto || choiceResponse.crypto}
                    sx={[
                        {
                            // height: "100px",
                            width: "45%",
                            padding: 2
                        },
                        {
                            '&:disabled': {
                                backgroundColor: Colors.fortune.primary.main,
                                color: Colors.fortune.primary.contrastText
                            }
                        }
                    ]}
                    variant={(initResponse.crypto || choiceResponse.crypto) ? "contained" : "outlined"}
                    onClick={handleClickPayFromOtherWallet}
                >
                    pay from other wallet
                </Button>
            </Box>
            <Collapse
                in={showSelectBlock}
            >
                <Box
                    ref={myRef}
                    marginY={3}
                    sx={
                        [
                            {
                                position: "relative",
                                border: "1px solid #51A693",
                                paddingX: 2,
                                paddingY: 2
                            },
                            {
                                "&:before": {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: -1,
                                    right: 125,
                                    width: 20,
                                    height: 20,
                                    borderTop: "1px solid #51A693",
                                    borderLeft: "1px solid #51A693",
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                }
                            }
                        ]
                    }
                >
                    {checkStatusResponse.payStatus.name === "awaiting fiat"
                        ? "Not implemented"
                        :
                        <PayByCrypto/>
                    }
                </Box>
            </Collapse>
            <Box
                component={"div"}
                sx={{
                    display: "flex",
                    width: "100%",
                    paddingY: 1,
                    alignItems: "center",
                    marginTop: 3,
                    backgroundColor: "#f8f8f8",
                }}
            >
                <ErrorOutlineIcon
                    sx={{
                        color: "#dcdcdc",
                        fontWeight: 100,
                        fontSize: "56px",
                        marginX: 2
                    }}

                />
                <Typography
                    width={"300px"}
                    fontWeight={600}
                >
                    Note: Fortune Wallet Benefits are fast transactions and low fees
                </Typography>
            </Box>
        </Box>
    );
};

export default Payment;