import {IInit} from "../model/IInit";

export interface InitState {
    invoiceID: string,
    initResponse: IInit
    error: null | string;
    loading?: boolean;
}

export enum InitActionTypes {
    SET_INVOICE_ID = "SET_INVOICE_ID",
    FETCH_INIT = "FETCH_INIT",
    FETCH_INIT_SUCCESS = "FETCH_INIT_SUCCESS",
    FETCH_INIT_ERROR = "FETCH_INIT_ERROR",
}

interface InvoiceActionTypes {
    type: InitActionTypes.SET_INVOICE_ID;
    payload: string;
}

interface FetchInitAction {
    type: InitActionTypes.FETCH_INIT;
}

interface FetchInitSuccessAction {
    type: InitActionTypes.FETCH_INIT_SUCCESS;
    payload: IInit;
}

interface FetchInitErrorAction {
    type: InitActionTypes.FETCH_INIT_ERROR;
    payload: string;
}

export type InitActions =
    InvoiceActionTypes
    | FetchInitAction
    | FetchInitSuccessAction
    | FetchInitErrorAction
