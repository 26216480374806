import {AuthState, LoginAction, LoginActionTypes} from "../../types/auth";

export const initialState: AuthState = {
    auth: false,
    responseAuth: {
        result: 0,
        desc: "",
        id: "",
        txId: "",
        status: {
            id: 0,
            name: "",
            final: null
        },
        payStatus: {
            id: 0,
            name: "",
            final: null
        },
        authStatus: {
            id: 0,
            name: "",
            final: null
        },
        crypto: {
            qr: "",
            address: "",
            invoiceAmount: 0,
            amount: 0,
            total: 0,
            currency: ""
        },
        fiat: {
            auth: null,
            amount: 0,
            total: 0,
            currency: ""
        }
    },
    phone: "",
    password: "",
    sentOTP: false,
    error: null,
    loading: true,
}

export const loginReducer = (state = initialState, action: LoginAction): AuthState => {
    switch (action.type) {
        case LoginActionTypes.SET_PHONE:
            return {...state, phone: action.payload, error: null}
        case LoginActionTypes.SET_IS_AUTH:
            return {...state, auth: action.payload}
        case LoginActionTypes.SET_LOADING:
            return {...state, loading: action.payload}
        case LoginActionTypes.SET_PASSWORD:
            return {...state, password: action.payload, error: null}
        case LoginActionTypes.FETCH_OTP:
            return {...state, loading: true, error: null}
        case LoginActionTypes.FETCH_OTP_SUCCESS:
            return {...state, loading: false, sentOTP: true}
        case LoginActionTypes.FETCH_OTP_ERROR:
            return {...state, loading: false, error: action.payload}
        case LoginActionTypes.FETCH_AUTH:
            // TODO FAKE AUTH
            return {...state, loading: true, error: null, auth: true}
            // return {...state, loading: true, error: null}
        case LoginActionTypes.FETCH_AUTH_SUCCESS:
            return {...state, loading: false, responseAuth: action.payload}
        case LoginActionTypes.FETCH_AUTH_ERROR:
            return {...state, loading: false, error: action.payload}
        default:
            return state
    }
}