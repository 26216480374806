import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../utils/config";
import {LoginAction, LoginActionTypes} from "../../types/auth";


export const authActionsCreator = {
    setPhone: (phone: string) => (dispatch: Dispatch<LoginAction>) => {
            dispatch({type: LoginActionTypes.SET_PHONE, payload: phone})
    },
    setPassword: (password: string) => (dispatch: Dispatch<LoginAction>) => {
            dispatch({type: LoginActionTypes.SET_PASSWORD, payload: password})
    },

    setIsAuth: (auth: boolean) => (dispatch: Dispatch<LoginAction>) => {
        if(!auth) {
            localStorage.removeItem("token");
            localStorage.removeItem("auth");
        }
            dispatch({type: LoginActionTypes.SET_IS_AUTH, payload: auth})
    },

    fetchOTP: (phone: string) => async (dispatch: Dispatch<LoginAction>) => {
        try {
            dispatch({type: LoginActionTypes.FETCH_OTP})
            const response = await axios.get('acquiring/sendOneTimePassword',{
                params: {phone: phone},
                baseURL: config.url.API_URL
            })
            if (response.data.status === 0){
                dispatch({type: LoginActionTypes.FETCH_OTP_SUCCESS})
            }
            if (response.data.status === 1){
                dispatch({type: LoginActionTypes.FETCH_OTP_ERROR, payload: response.data.message})
            }
        } catch (e) {
            dispatch({type: LoginActionTypes.FETCH_OTP_ERROR, payload: "Error"})
        }
    },
    fetchLogin: (data: object, id: string) => async (dispatch: Dispatch<LoginAction>) => {
        try {
            dispatch({type: LoginActionTypes.FETCH_AUTH})
            const response = await axios.post(`acquiring/auth/${id}`,data,{
                baseURL: config.url.API_URL
            });
            if(response.data.result > 400) {
                dispatch({type: LoginActionTypes.FETCH_AUTH_ERROR, payload: "invalid password or login"})
            } else {
                dispatch({type: LoginActionTypes.FETCH_AUTH_SUCCESS, payload: response.data})
                // localStorage.setItem("token", response.data.id)
                // localStorage.setItem("auth", "true")
            }
        } catch (e) {
            dispatch({type: LoginActionTypes.FETCH_OTP_ERROR, payload: "invalid password or login"})
        }
    },
    setLoading: (loading: boolean) =>  (dispatch: Dispatch<LoginAction>) =>{
            dispatch({type: LoginActionTypes.SET_LOADING, payload: loading})
    }
}