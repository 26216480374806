/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect} from 'react';
import {
    Backdrop,
    CircularProgress,
    Container,
    CssBaseline,
    Typography
} from "@mui/material";
import {useTypeSelector} from "./hooks/useTypeSelector";
import {useActions} from "./hooks/useActions";
import {BrowserRouter} from "react-router-dom";
import "./styles/style.css";
import InitPartner from "./components/InitPartner";
const App: FC = () => {
    const {error, loading} = useTypeSelector(state => state.dict);
    const {
        fetchAuthStatusList,
        fetchAcquiringStatus,
        fetchStatusList,
        fetchPayStatusList,
    } = useActions();

    useEffect(() => {
        fetchAuthStatusList();
        fetchAcquiringStatus();
        fetchStatusList();
        fetchPayStatusList();
    }, [])

    if (loading < 4) {
        return <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>
    }
    if (error) {
        return <Typography>{error}</Typography>
    }
    return (
        <BrowserRouter>
            <Container
                component="main"
                maxWidth="xs"
                sx={{
                    marginTop: 1,
                    marginBottom: 11,
                }}
            >
                <CssBaseline/>
            {/*<div*/}
            {/*    style={{*/}
            {/*        display: "flex",*/}
            {/*        justifyContent: "cener",*/}
            {/*        alignItems: 'center',*/}
            {/*        overflow: 'hidden',*/}
            {/*        height: '100vh',*/}
            {/*        padding: 0,*/}
            {/*        margin: 0,*/}
            {/*        backgroundColor: '#000',*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <img*/}
            {/*        style={{*/}
            {/*            width: '100%',*/}
            {/*            height: 'auto'*/}
            {/*        }}*/}
            {/*        src={src}*/}
            {/*        alt="Coming soon"*/}
            {/*    />*/}
            {/*</div>*/}

                <InitPartner/>
            </Container>
        </BrowserRouter>
    );
};

export default App;
