import {ICheckStatus} from "../model/ICheckStatus";

export interface CheckStatusState {
    checkStatusResponse: ICheckStatus
    error: null | string;
    loading?: boolean;
}

export enum CheckStatusActionTypes {
    FETCH_CHECK_STATUS = "FETCH_CHECK_STATUS",
    FETCH_CHECK_STATUS_SUCCESS = "FETCH_CHECK_STATUS_SUCCESS",
    FETCH_CHECK_STATUS_ERROR = "FETCH_CHECK_STATUS_ERROR",
}


interface FetchCheckStatusAction {
    type: CheckStatusActionTypes.FETCH_CHECK_STATUS;
}

interface FetchCheckStatusSuccessAction {
    type: CheckStatusActionTypes.FETCH_CHECK_STATUS_SUCCESS;
    payload: ICheckStatus;
}

interface FetchCheckStatusErrorAction {
    type: CheckStatusActionTypes.FETCH_CHECK_STATUS_ERROR;
    payload: string;
}

export type CheckStatusActions =
    FetchCheckStatusAction
    | FetchCheckStatusSuccessAction
    | FetchCheckStatusErrorAction
