import React from 'react';
import {Typography} from "@mui/material";

const Error = ({children} : React.PropsWithChildren<any>) => {
    return (
        <Typography>
            {children ? children :
                "Your purchase was not found"
            }
        </Typography>
    );
};

export default Error;