export interface IStreamPaymentID {
    checkoutId: string;
    loading: boolean;
    error: null | string;
}

export enum StreamPaymentIDActionTypes {
    FETCH_STREAM_PAYMENT_ID = 'FETCH_STREAM_PAYMENT_ID',
    FETCH_STREAM_PAYMENT_ID_SUCCESS = 'FETCH_STREAM_PAYMENT_ID_SUCCESS',
    FETCH_STREAM_PAYMENT_ID_ERROR = 'FETCH_STREAM_PAYMENT_ID_ERROR',
}

interface FetchStreamPaymentIDAction {
    type: StreamPaymentIDActionTypes.FETCH_STREAM_PAYMENT_ID;
}

interface FetchStreamPaymentSuccessAction {
    type: StreamPaymentIDActionTypes.FETCH_STREAM_PAYMENT_ID_SUCCESS;
    payload: string;
}

interface FetchStreamPaymentErrorAction {
    type: StreamPaymentIDActionTypes.FETCH_STREAM_PAYMENT_ID_ERROR;
    payload: string;
}

export type StreamPaymentsAction = FetchStreamPaymentIDAction | FetchStreamPaymentSuccessAction | FetchStreamPaymentErrorAction
