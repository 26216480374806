import {combineReducers} from "redux";
import {dictReducer} from "./dictReducer";
import {initReducer} from "./initReducer";
import {choiceReducer} from "./choiceReducer";
import {checkStatusReducer} from "./checkStatusReducer";
import {merchantReducer} from "./merchantReducer";
import {streamPaymentsReducer} from "./streamPaymentsReducer";
import {loginReducer} from "./loginReducer";



export const rootReducer = combineReducers({
    dict: dictReducer,
    init: initReducer,
    choice: choiceReducer,
    checkStatus: checkStatusReducer,
    merchant: merchantReducer,
    streamPayments: streamPaymentsReducer,
    auth: loginReducer,
})

export type RootState = ReturnType<typeof rootReducer>
