import {InitActions, InitActionTypes, InitState} from "../../types/init";

export const initialState: InitState = {
    invoiceID: "",
    initResponse: {
        result: 10,
        desc: "",
        merchantName: "",
        transactionId: "",
        clientId: "",
        transactionDesc: "",
        amount: 0,
        currency: "",
        redirectUrl: "",
        callbackUrl: "",
        crypto: false,
        partnerCode: ""
    },
    error: null,
    loading: false
}

export const initReducer = (state = initialState, action: InitActions): InitState => {
    switch (action.type) {
        case InitActionTypes.SET_INVOICE_ID:
            return {...state, invoiceID: action.payload}
        case InitActionTypes.FETCH_INIT:
            return {...state, loading: true, error: null}
        case InitActionTypes.FETCH_INIT_SUCCESS:
            return {...state, loading: false, initResponse: action.payload}
        case InitActionTypes.FETCH_INIT_ERROR:
            return {...state, loading: false, error: action.payload}
        default:
            return state
    }
}