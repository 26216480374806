import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../utils/config";
import {DictActions, DictActionTypes} from "../../types/dict";


export const dictActionsCreator = {
    fetchStatusList: () => async (dispatch: Dispatch<DictActions>) => {
        try {
            dispatch({type: DictActionTypes.FETCH_STATUS_LIST})
            const response = await axios.get('dict/statusList', {
                baseURL: config.url.API_URL
            })
            dispatch({type: DictActionTypes.FETCH_STATUS_LIST_SUCCESS, payload: response.data})
        } catch (e: any) {
            dispatch({
                type: DictActionTypes.FETCH_STATUS_LIST_ERROR,
                payload: `Error: FETCH_STATUS_LIST___${JSON.stringify(e.response)}`
            })
        }
    },
    fetchPayStatusList: () => async (dispatch: Dispatch<DictActions>) => {
        try {
            dispatch({type: DictActionTypes.FETCH_PAY_STATUS_LIST})
            const response = await axios.get('dict/payStatusList', {
                baseURL: config.url.API_URL
            })
            dispatch({type: DictActionTypes.FETCH_PAY_STATUS_LIST_SUCCESS, payload: response.data})
        } catch (e: any) {
            dispatch({
                type: DictActionTypes.FETCH_PAY_STATUS_LIST_ERROR,
                payload: `Error: FETCH_PAY_STATUS_LIST___${JSON.stringify(e.response)}`
            })
        }
    },
    fetchCryptoCurrencyList: (amount: number, partnerId: number) => async (dispatch: Dispatch<DictActions>) => {
        try {
            dispatch({type: DictActionTypes.FETCH_CRYPTO_CURRENCY_LIST})
            const response = await axios.get(`hybridAcquiring/cryptoList?amount=${amount}&partnerId=${partnerId}`, {
                baseURL: config.url.API_URL
            })
            dispatch({type: DictActionTypes.FETCH_CRYPTO_CURRENCY_LIST_SUCCESS, payload: response.data})
        } catch (e: any) {
            dispatch({
                type: DictActionTypes.FETCH_CRYPTO_CURRENCY_LIST_ERROR,
                payload: `Error: FETCH_CRYPTO_CURRENCY_LIST___${JSON.stringify(e.response)}`
            })
        }
    },
    fetchAuthStatusList: () => async (dispatch: Dispatch<DictActions>) => {
        try {
            dispatch({type: DictActionTypes.FETCH_AUTH_STATUS_LIST})
            const response = await axios.get('dict/authStatusList', {
                baseURL: config.url.API_URL
            })
            dispatch({type: DictActionTypes.FETCH_AUTH_STATUS_LIST_SUCCESS, payload: response.data})
        } catch (e: any) {
            dispatch({
                type: DictActionTypes.FETCH_AUTH_STATUS_LIST_ERROR,
                payload: `Error: FETCH_AUTH_STATUS_LIST___${JSON.stringify(e.response)}`
            })
        }
    },
    fetchAcquiringStatus: () => async (dispatch: Dispatch<DictActions>) => {
        try {
            dispatch({type: DictActionTypes.FETCH_ACQUIRING_STATUS})
            const response = await axios.get('dict/acquiringStatus', {
                baseURL: config.url.API_URL
            })
            if (response.data.result === 1) {
                dispatch({
                    type: DictActionTypes.FETCH_ACQUIRING_STATUS_ERROR,
                    payload: `Error: FETCH_ACQUIRING_STATUS___${JSON.stringify(response.data)}`
                })
            }
            if (response.data.result === 0) {
                dispatch({type: DictActionTypes.FETCH_ACQUIRING_STATUS_SUCCESS, payload: response.data})
            }
        } catch (e: any) {
            dispatch({
                type: DictActionTypes.FETCH_ACQUIRING_STATUS_ERROR,
                payload: `Error: FETCH_ACQUIRING_STATUS___${JSON.stringify(e.response)}`
            })
        }
    },
}