import React from 'react';
import {Box, Card, CardContent, CardMedia, Link, Typography} from "@mui/material";
import image from "../../assets/evercashTest.png";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const SuccessPay = () => {
    return (
        <Box
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            paddingY={5}
        >
            <Typography variant={"h4"} color={"#299400"}>
                Payment Completed
            </Typography>
            <Box>
                <CheckCircleIcon
                    color={"success"}
                    fontSize={"large"}
                />
            </Box>
            <Card sx={{maxWidth: 345}}>

                <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                        Online Casino Deposit
                    </Typography>

                    <CardMedia
                        component="img"
                        height="340"
                        image={image}
                        alt="sneakers"
                    />
                    <Box
                        display={"flex"}
                        marginBottom={3}
                    >
                        <Typography marginRight={3} variant="body1">
                            5 Euro
                        </Typography>
                        <Typography variant="body1">
                            Qty: 1
                        </Typography>
                    </Box>

                    <Typography variant="body2" color="text.secondary">
                        Here you could use Hybrid Acquiring Payments, supported by&nbsp;the
                        ecosystem payment gateways, and allowing you to&nbsp;pay by&nbsp;all
                        the major cryptocurrencies. Pi&nbsp;Union holding product (<Link href={"www.piunion.eu"}>www.piunion.eu</Link>)
                    </Typography>
                    <Box
                        marginTop={3}
                    >
                        <Typography marginBottom={1} variant="body1">
                            Order Total: 5 Euro
                        </Typography>
                        <Typography variant="body1">
                            Order Totals include VAT
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </Box>
    );
};

export default SuccessPay;