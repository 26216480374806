import MokMerchant from "../pages/MokMerchant";
import Merchant from "../pages/Merchant";
import {IRoutes} from "../types/route";
import Login from "../components/Login";
import Payment from "../components/Payment";
import OrderEupi from "../components/OrderEupi";
import SuccessPay from "../components/testFortune/SuccessPay";
import FortuneMerchantTest from "../components/testFortune/FortuneMerchantTest";
import EvercashPayment from "../components/Evercash/EvercashPayment";

export enum RouteNamesFortune {
    MERCHANT = '/merchant-page',
    MERCHANT_SIMPLISTIC = '/merchant-page-simplistic',
    COMPLETED = '/merchant-completed',
    LOGIN = '/login',
    PAYMENT = '/',
    ORDER_EUPI = '/order/EuPI',
    ORDER_MERCH = '/shop/order/',
    ERROR = '/error'
}

export enum RouteNamesEvercash {
    MERCHANT = '/merchant-page',
    MERCHANT_SIMPLISTIC = '/merchant-page-simplistic',
    COMPLETED = '/merchant-completed',
    LOGIN = '/login',
    PAYMENT = '/',
    ORDER_EUPI = '/order/EuPI',
    ORDER_MERCH = '/shop/order/',
    ERROR = '/error'
}

export enum RouteNamesJackPayments {
    MERCHANT = '/merchant-page',
    LOGIN = '/login',
    PAYMENT = '/payment',
    ORDER_EUPI = '/order/EuPI',
    ORDER_MERCH = '/shop/order/',
    ERROR = '/error'
}

export const publicRoutesFortune: IRoutes[] = [
    {
        path: RouteNamesFortune.PAYMENT,
        component: Payment,
        exact: true
    },
    // TODO dev route
    {
        path: RouteNamesFortune.MERCHANT,
        component: Merchant,
        // component: EvercashMerchantTest,
        exact: true
    },{
        path: RouteNamesFortune.MERCHANT_SIMPLISTIC,
        component: FortuneMerchantTest,
        exact: true
    },
    {
        path: RouteNamesFortune.ORDER_EUPI,
        component: OrderEupi,
        exact: true
    },
    {
        path: RouteNamesFortune.ORDER_MERCH,
        component: MokMerchant,
        exact: true
    },{
        path: RouteNamesFortune.COMPLETED,
        component: SuccessPay,
        exact: true
    },
]

export const publicRoutesEvercash: IRoutes[] = [
    {
        path: RouteNamesEvercash.PAYMENT,
        component: EvercashPayment,
        exact: true
    },
    {
        path: RouteNamesEvercash.MERCHANT,
        component: Merchant,
        exact: true
    },{
        path: RouteNamesEvercash.MERCHANT_SIMPLISTIC,
        component: FortuneMerchantTest,
        exact: true
    },
    {
        path: RouteNamesEvercash.ORDER_EUPI,
        component: OrderEupi,
        exact: true
    },
    {
        path: RouteNamesEvercash.ORDER_MERCH,
        component: MokMerchant,
        exact: true
    },{
        path: RouteNamesEvercash.COMPLETED,
        component: SuccessPay,
        exact: true
    },
]

export const privateRoutesFortune: IRoutes[] = [
    {
        path: RouteNamesFortune.LOGIN,
        component: Login,
        exact: true
    },
];

export const publicRoutesJackPayments: IRoutes[] = [
    {
        path: RouteNamesJackPayments.PAYMENT,
        component: Payment,
        exact: true
    },
    {
        path: RouteNamesJackPayments.LOGIN,
        component: Login,
        exact: true
    },
    // TODO dev route
    {
        path: RouteNamesJackPayments.MERCHANT,
        component: Merchant,
        exact: true
    },
    {
        path: RouteNamesJackPayments.ORDER_MERCH,
        component: MokMerchant,
        exact: true
    },
]

export const privateRoutesJackPayments: IRoutes[] = [
    {
        path: RouteNamesJackPayments.ORDER_EUPI,
        component: OrderEupi,
        exact: true
    },
];