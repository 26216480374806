import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../utils/config";
import {InitActions, InitActionTypes} from "../../types/init";


export const initActionsCreator = {

    setInvoice: (id: string) => (dispatch: Dispatch<InitActions>) => {
        dispatch({type: InitActionTypes.SET_INVOICE_ID, payload: id})
    },
    fetchInit: (id: string) => async (dispatch: Dispatch<InitActions>) => {
        try {
            dispatch({type: InitActionTypes.FETCH_INIT})
            const response = await axios.get(`hybridAcquiring/invoice/${id}/`, {baseURL: config.url.API_URL})
            if(response.data.result === 0) {
                dispatch({type: InitActionTypes.FETCH_INIT_SUCCESS, payload: response.data})
            } else if (response.data.result === 400 || response.data.result  > 401 ) {
                dispatch({type: InitActionTypes.FETCH_INIT_ERROR, payload: `ERROR!!! ${response.data.desc}`})
            }
        }catch (e: any) {
            dispatch({type: InitActionTypes.FETCH_INIT_ERROR, payload: `ERROR!!! acquiring/init/: ${JSON.stringify(e.response)}`})
        }
    },
}