import {IStatusList} from "../model/dicts/IStatusList";
import {IPayStatusList} from "../model/dicts/IPayStatusList";
import {ICryptoCurrencyList} from "../model/dicts/ICryptoCurrencyList";
import {IAuthStatusList} from "../model/dicts/IAuthStatusList";
import {IAcquiringStatus} from "../model/dicts/IAcquiringStatus";

export interface DictState {
    statusList: IStatusList[],
    payStatusList: IPayStatusList[],
    cryptoCurrencyList: ICryptoCurrencyList,
    authStatusList: IAuthStatusList[],
    acquiringStatus: IAcquiringStatus,

    loading: number,
    error: null | string
}

export enum DictActionTypes {
    FETCH_STATUS_LIST = "FETCH_STATUS_LIST",
    FETCH_STATUS_LIST_SUCCESS = "FETCH_STATUS_LIST_SUCCESS",
    FETCH_STATUS_LIST_ERROR = "FETCH_STATUS_LIST_ERROR",
    FETCH_PAY_STATUS_LIST = "FETCH_PAY_STATUS_LIST",
    FETCH_PAY_STATUS_LIST_SUCCESS = "FETCH_PAY_STATUS_LIST_SUCCESS",
    FETCH_PAY_STATUS_LIST_ERROR = "FETCH_PAY_STATUS_LIST_ERROR",
    FETCH_CRYPTO_CURRENCY_LIST = "FETCH_CRYPTO_CURRENCY_LIST",
    FETCH_CRYPTO_CURRENCY_LIST_SUCCESS = "FETCH_CRYPTO_CURRENCY_LIST_SUCCESS",
    FETCH_CRYPTO_CURRENCY_LIST_ERROR = "FETCH_CRYPTO_CURRENCY_LIST_ERROR",
    FETCH_AUTH_STATUS_LIST = "FETCH_AUTH_STATUS_LIST",
    FETCH_AUTH_STATUS_LIST_SUCCESS = "FETCH_AUTH_STATUS_LIST_SUCCESS",
    FETCH_AUTH_STATUS_LIST_ERROR = "FETCH_AUTH_STATUS_LIST_ERROR",
    FETCH_ACQUIRING_STATUS = "FETCH_ACQUIRING_STATUS",
    FETCH_ACQUIRING_STATUS_SUCCESS = "FETCH_ACQUIRING_STATUS_SUCCESS",
    FETCH_ACQUIRING_STATUS_ERROR = "FETCH_ACQUIRING_STATUS_ERROR",
}

interface FetchStatusList {
    type: DictActionTypes.FETCH_STATUS_LIST
}

interface FetchStatusListSuccess {
    type: DictActionTypes.FETCH_STATUS_LIST_SUCCESS
    payload: IStatusList[]
}

interface FetchStatusListError {
    type: DictActionTypes.FETCH_STATUS_LIST_ERROR,
    payload: string
}

interface FetchPayStatusList {
    type: DictActionTypes.FETCH_PAY_STATUS_LIST
}

interface FetchPayStatusListSuccess {
    type: DictActionTypes.FETCH_PAY_STATUS_LIST_SUCCESS
    payload: IPayStatusList[]
}

interface FetchPayStatusListError {
    type: DictActionTypes.FETCH_PAY_STATUS_LIST_ERROR,
    payload: string
}

interface FetchCryptoCurrencyList {
    type: DictActionTypes.FETCH_CRYPTO_CURRENCY_LIST
}

interface FetchCryptoCurrencyListSuccess {
    type: DictActionTypes.FETCH_CRYPTO_CURRENCY_LIST_SUCCESS
    payload: ICryptoCurrencyList
}

interface FetchCryptoCurrencyListError {
    type: DictActionTypes.FETCH_CRYPTO_CURRENCY_LIST_ERROR,
    payload: string
}

interface FetchAuthStatusList {
    type: DictActionTypes.FETCH_AUTH_STATUS_LIST
}

interface FetchAuthStatusListSuccess {
    type: DictActionTypes.FETCH_AUTH_STATUS_LIST_SUCCESS
    payload: IAuthStatusList[]
}

interface FetchAuthStatusListError {
    type: DictActionTypes.FETCH_AUTH_STATUS_LIST_ERROR,
    payload: string
}

interface FetchAcquiringStatus {
    type: DictActionTypes.FETCH_ACQUIRING_STATUS
}

interface FetchAcquiringStatusSuccess {
    type: DictActionTypes.FETCH_ACQUIRING_STATUS_SUCCESS
    payload: IAcquiringStatus
}

interface FetchAcquiringStatusError {
    type: DictActionTypes.FETCH_ACQUIRING_STATUS_ERROR,
    payload: string
}

export type DictActions =
    FetchStatusList
    | FetchStatusListSuccess
    | FetchStatusListError
    | FetchPayStatusList
    | FetchPayStatusListSuccess
    | FetchPayStatusListError
    | FetchCryptoCurrencyList
    | FetchCryptoCurrencyListSuccess
    | FetchCryptoCurrencyListError
    | FetchAuthStatusList
    | FetchAuthStatusListSuccess
    | FetchAuthStatusListError
    | FetchAcquiringStatus
    | FetchAcquiringStatusSuccess
    | FetchAcquiringStatusError