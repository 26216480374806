import {DictActions, DictActionTypes, DictState} from "../../types/dict";

export const InitialState: DictState = {
    statusList: [
        {
            id: 0,
            name: "",
            description: "",
            final: false
        }
    ],
    payStatusList: [
        {
            id: 0,
            name: "",
            description: "",
            failed: false,
            final: false
        }
    ],
    cryptoCurrencyList: {
        amount: 0,
        partnerId: 0,
        partnerCommissionPercent: 0,
        cryptoRate: [
            {
               name: "",
               code: "",
               logo: "",
               minDeposit: 0,
               amount: 0,
               available: false
            }
        ]
    },
    authStatusList: [
        {
            id: 0,
            name: "",
            description: "",
            final: false
        }
    ],
    acquiringStatus: {
        result: 10,
        sp: {
            code: "",
            name: "",
            enable: false
        },
        rp: {
            code: "",
            name: "",
            enable: false
        }
    },
    loading: 0,
    error: null
}

export const dictReducer = (state = InitialState, action: DictActions): DictState => {
    switch (action.type) {
        case DictActionTypes.FETCH_STATUS_LIST:
            return {...state, error: null}
        case DictActionTypes.FETCH_STATUS_LIST_SUCCESS:
            return {...state, loading: state.loading + 1, statusList: action.payload}
        case DictActionTypes.FETCH_STATUS_LIST_ERROR:
            return {...state, loading: state.loading + 1, error: action.payload}
        case DictActionTypes.FETCH_PAY_STATUS_LIST:
            return {...state, error: null}
        case DictActionTypes.FETCH_PAY_STATUS_LIST_SUCCESS:
            return {...state, loading: state.loading + 1, payStatusList: action.payload}
        case DictActionTypes.FETCH_PAY_STATUS_LIST_ERROR:
            return {...state, loading: state.loading + 1, error: action.payload}
        case DictActionTypes.FETCH_CRYPTO_CURRENCY_LIST:
            return {...state, error: null}
        case DictActionTypes.FETCH_CRYPTO_CURRENCY_LIST_SUCCESS:
            return {...state, loading: state.loading + 1, cryptoCurrencyList: action.payload}
        case DictActionTypes.FETCH_CRYPTO_CURRENCY_LIST_ERROR:
            return {...state, loading: state.loading + 1, error: action.payload}
        case DictActionTypes.FETCH_AUTH_STATUS_LIST:
            return {...state, error: null}
        case DictActionTypes.FETCH_AUTH_STATUS_LIST_SUCCESS:
            return {...state, loading: state.loading + 1, authStatusList: action.payload}
        case DictActionTypes.FETCH_AUTH_STATUS_LIST_ERROR:
            return {...state, loading: state.loading + 1, error: action.payload}
        case DictActionTypes.FETCH_ACQUIRING_STATUS:
            return {...state, error: null}
        case DictActionTypes.FETCH_ACQUIRING_STATUS_SUCCESS:
            return {...state, loading: state.loading + 1, acquiringStatus: action.payload}
        case DictActionTypes.FETCH_ACQUIRING_STATUS_ERROR:
            return {...state, loading: state.loading + 1, error: action.payload}
        default:
            return state
    }
}