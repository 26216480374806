import {Dispatch} from "redux";
import axios from "axios";
import {config} from "../../utils/config";
import {CheckStatusActions, CheckStatusActionTypes} from "../../types/checkStatus";


export const checkStatusActionsCreator = {
    fetchCheckStatus: (invoiceId: string) => async (dispatch: Dispatch<CheckStatusActions>) => {
        try {
            dispatch({type: CheckStatusActionTypes.FETCH_CHECK_STATUS})
            const response = await axios.get(`hybridAcquiring/invoice/${invoiceId}/checkStatus`, {baseURL: config.url.API_URL})
            if(response.data.result === 0) {
                dispatch({type: CheckStatusActionTypes.FETCH_CHECK_STATUS_SUCCESS, payload: response.data})
            } else if (response.data.result === 400 || response.data.result  > 401 ) {
                dispatch({type: CheckStatusActionTypes.FETCH_CHECK_STATUS_ERROR, payload: `ERROR!!! ${response.data.desc}`})
            }
        }catch (e: any) {
            dispatch({type: CheckStatusActionTypes.FETCH_CHECK_STATUS_ERROR, payload: `ERROR!!! acquiring/CheckStatus/: ${JSON.stringify(e.response)}`})
        }
    },
}