/* eslint-disable react-hooks/exhaustive-deps */
import React, {FC, useEffect, useState} from 'react';

import {useTypeSelector} from "../hooks/useTypeSelector";
import {useActions} from "../hooks/useActions";

import {Backdrop, CircularProgress} from "@mui/material";

import FortuneRoute from "./PartnersFlowRoute/FortuneRoute";
import JackPaymentsRoute from "./PartnersFlowRoute/JackPaymentsRoute";
import Error from "./Error";
import EvercashRoute from "./PartnersFlowRoute/EvercashRoute";
import FortuneMerchantTest from "./testFortune/FortuneMerchantTest";

const InitPartner: FC = () => {
    const {loading, error, initResponse} = useTypeSelector(state => state.init);
    const search = window.location.search.substring(1);
    const demo = window.location.search.substring(1);
    const [evercashDemo, setEvercashDemo] = useState(false);
    const {setInvoice, fetchInit, fetchCheckStatus} = useActions();

    useEffect(() => {
        if (demo.includes("evercash-demo")) {
            setEvercashDemo(true);
        }
        if (search.includes("initId")) {
            let ID = JSON.parse('{"' + search.replace(/&/g, '","').replace(/=/g, '":"') + '"}', function (key, value) {
                return key === "" ? value : decodeURIComponent(value)
            })
            setInvoice(ID.initId);
            fetchInit(ID.initId);
            fetchCheckStatus(ID.initId)
        }
    }, [])
    if (loading) {
        return <Backdrop
            sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1}}
            open={true}
        >
            <CircularProgress color="inherit"/>
        </Backdrop>
    }

    if (error) {
        return <Error>{error}</Error>
    }

    if (evercashDemo) {
        return (
            <FortuneMerchantTest/>
        );
    }

    return (
        initResponse.partnerCode === "frtn"
            ? <FortuneRoute/>
            : initResponse.partnerCode === "evercash"
                ? <EvercashRoute/>
                : initResponse.partnerCode === "jp"
                    ? <JackPaymentsRoute/>
                    :
                    process.env.REACT_APP_STAGE === 'production'
                        ?
                        <Error>
                            Your purchase was not found
                        </Error>
                        // : <Merchant/>
        : <EvercashRoute/>

    );
};

export default InitPartner;