import React from 'react';
import {Alert, Box, Snackbar, Typography} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import {useTypeSelector} from "../hooks/useTypeSelector";

const CopyAmount = () => {
    const {checkStatusResponse} = useTypeSelector(state => state.checkStatus);
    const {choiceResponse} = useTypeSelector(state => state.choice);
    const [open, setOpen] = React.useState(false);

    const copyAmount = (amount: any) => {
        navigator.clipboard.writeText(amount)
        setOpen(true);
    };
    const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    return (

        <Box>
            <Snackbar
                anchorOrigin={{vertical: 'top', horizontal: 'right'}}
                open={open}
                autoHideDuration={6000}
                onClose={handleClose}
            >
                <Alert
                    onClose={handleClose}
                    severity="success"
                    sx={{width: '100%'}}
                >
                    Amount copy
                </Alert>
            </Snackbar>
            <Typography
                paragraph
                align="left"
                marginBottom={0}
                color="#A1A1A1"
            >
                Total price in crypto currency
            </Typography>
            <Typography
                variant="h4"
                onClick={copyAmount.bind(null, checkStatusResponse.crypto?.expected.toFixed(9))}
                align="left"
                sx={{
                    cursor: "pointer",
                }}
            >
                {`${checkStatusResponse.crypto?.expected.toFixed(9)} ${choiceResponse.currency || checkStatusResponse.crypto?.currency}  `}
                <sup
                    style={{
                        lineHeight: 0,
                        fontSize: 10
                }}
                >copy</sup><ContentCopyIcon/>
            </Typography>
        </Box>
    );
};

export default CopyAmount;