import {CheckStatusActions, CheckStatusActionTypes, CheckStatusState} from "../../types/checkStatus";

export const InitialState: CheckStatusState = {
    checkStatusResponse: {
        result: 10,
        desc: "",
        id: "",
        txId: "",
        status: {
            id: 0,
            name: "",
            final: false
        },
        payStatus: {
            id: 0,
            name: "",
            final: false
        },
        authStatus: {
            id: 0,
            name: "",
            final: false
        },
        crypto: {
            qr: "",
            address: "",
            invoiceAmount: 0,
            amount: 0,
            total: 0,
            expected: 0,
            currency: "",
            dueDate: 0,
            totalAmount: 0,
        },
        fiat: {
            auth: false,
            amount: 0,
            total: 0,
            currency: ""
        }
    },
    error: null,
    loading: false
}

export const checkStatusReducer = (state = InitialState, action: CheckStatusActions): CheckStatusState => {
    switch (action.type) {
        case CheckStatusActionTypes.FETCH_CHECK_STATUS:
            return {...state, loading: true, error: null}
        case CheckStatusActionTypes.FETCH_CHECK_STATUS_SUCCESS:
            return {...state, loading: false, checkStatusResponse: action.payload}
        case CheckStatusActionTypes.FETCH_CHECK_STATUS_ERROR:
            return {...state, loading: false, error: action.payload}
        default:
            return state
    }
}