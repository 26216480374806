/* eslint-disable react-hooks/exhaustive-deps */
import React, {ChangeEvent, useEffect, useState} from 'react';

import {useTypeSelector} from "../hooks/useTypeSelector";
import {useActions} from "../hooks/useActions";

import axios from "axios";

import {config} from "../utils/config";

import {
    Box, Button,
    IconButton,
    InputAdornment, Link,
    Modal,
    TextField, Typography
} from '@mui/material';
import {Visibility, VisibilityOff} from "@mui/icons-material";
import {Colors} from "../styles/styles";

const Login = () => {
    const [seconds, setSeconds] = React.useState(120);
    const {phone, password, sentOTP, error} = useTypeSelector(state => state.auth);
    const {initResponse, invoiceID} = useTypeSelector(state => state.init);
    const {setPhone, setPassword, fetchOTP, fetchLogin} = useActions();
// TODO AUTH NO working
    const sendRegistration = async () => {
        const response = await axios.get(`acquiring/goToReg/${invoiceID}`, {baseURL: config.url.API_URL})
        if (response.data.status === 0) {
            document.location.href = config.url.URL_REGISTRATION + `?partner=${initResponse.partnerCode}&id=${invoiceID}`;
        }
    }

    useEffect(() => {
        if (seconds > 0 && sentOTP) {
            setTimeout(() => {
                setSeconds(seconds - 1)
            }, 1000);
        }
        if (seconds >= 0 && !sentOTP) {
            setSeconds(120)
        }
    }, [seconds, sentOTP])

    const sendOTP = () => {
        setSeconds(120)
        fetchOTP(phone)
    }
    const sendAUTH = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
        fetchLogin({phone: phone, password: password}, invoiceID)
    }

    const [values, setValues] = useState({
        amount: '',
        password: '',
        weight: '',
        weightRange: '',
        showPassword: false,
    });

    const handleClickShowPassword = () => {
        setValues({
            ...values,
            showPassword: !values.showPassword,
        });
    };

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Box
            display="flex"
            flexDirection="column"
        >
            <Typography
                variant="h5"
                align="left"
                color={"primary"}
            >
                Login
            </Typography>
            <TextField
                color="primary"
                fullWidth
                type="tel"
                id="phone"
                label="Phone"
                error={!!error}
                helperText={error}
                sx={{
                    marginBottom: 2
                }}
                onChange={(event: ChangeEvent<HTMLInputElement>) => setPhone(event.target.value)}
                margin="normal"
                InputProps={{
                    startAdornment: <InputAdornment position="start">
                        +
                    </InputAdornment>,
                }}
            />
            <Button
                sx={{
                    width: "10pc"
                }}
                variant="contained"
                onClick={sendOTP}
            >
                Login
            </Button>
            <Typography
                variant="body1"
                align="left"
                marginTop={3}
                color={"secondary"}
            >
                New user?
                <Link
                    onClick={sendRegistration}
                    sx={{
                        cursor: "pointer"
                    }}
                    color={"primary"}
                    // href={`${config.url.URL_REGISTRATION_FORTUNE}?partner=${initResponse.partnerCode}&initId=${invoiceID}`}
                    target="_blank"
                    marginLeft={1}
                >
                    Registration
                </Link>
            </Typography>
            <Modal
                open={sentOTP}
                // onClose={}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={Colors.modal}
                >
                    <Typography
                        variant="h5"
                        align="left"
                        color={"primary"}
                    >
                        Enter confirmation code
                    </Typography>
                    <TextField
                        fullWidth
                        type={values.showPassword ? "text" : "password"}
                        name="password"
                        error={!!error}
                        helperText={error}
                        margin="dense"
                        id="password"
                        label="Confirm code"
                        value={password}
                        onChange={(event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)}
                        InputProps={{
                            endAdornment: <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                >
                                    {values.showPassword ? <VisibilityOff/> : <Visibility/>}
                                </IconButton>
                            </InputAdornment>,
                        }}
                    />
                    <Box
                        display="flex"
                        justifyContent="space-between"
                        marginTop={2}
                    >
                        <Button
                            variant="contained"
                            disabled={seconds > 0}
                            onClick={sendOTP}
                        >
                            Resend {seconds > 0 && seconds}
                        </Button>
                        <Button
                            variant="contained"
                            onClick={sendAUTH}
                        >
                            Confirm
                        </Button>
                    </Box>
                </Box>
            </Modal>
        </Box>
    );
};

export default Login;